import service from "../service";

export const getSpace = (id) => {
  return service.get(`showcase/${id}/space`);
};

export const getTiles = (id) => {
  return service.get(`showcase/${id}/menu`);
};

export const getMiniMap = (spaceId) => {
  return service.get(`showcase/${spaceId}/minimapInfo`);
};

export const getImageForMinimap = (imageId) => {
  return service.get(`showcase/file/${imageId}`);
};

export const getPoi = (spaceId) => {
  return service.get(`showcase/${spaceId}/poi`);
};

export const getSpaceByPrettyUrl = (addPrettyUrl) => {
  return service.get(`showcase/prettyUrl/${addPrettyUrl}`);
};

// https://dev.spadmin.akrotonx.com/home/space/17/?m=Mqe2Rbkviic
