/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import { CustomInput } from "../CustomInput/CustomInput";
import { CustomModal } from "../CustomModal/CustomModal";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const ModalTileType = ({ close, typeTile, typeLink, current, urlMedia }) => {
  const [data, setData] = useState(null);
  const renderType = () => {
    let type = typeLink?.value;
    switch (type) {
      case "text":
        return (
          <>
            <div className="tile-modal__field">
              <div
                dangerouslySetInnerHTML={{ __html: data?.descriptions?.value }}
                className="tile-modal--main_text"
              ></div>
            </div>
          </>
        );
      case "video":
        return (
          <>
            <div className="tile-modal__field tile-modal__field--size">
              {data?.video?.value && (
                <div className="tile-modal__upload-img">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={data?.video?.value}
                    light={true}
                    controls={true}
                  />
                </div>
              )}
            </div>
          </>
        );
      case "iframe":
        return (
          <>
            {data?.url?.value && (
              <iframe
                loading="lazy"
                className="tile-modal-type__iframe lazyload"
                referrerPolicy="no-referrer"
                src={data?.url?.value}
              />
            )}
          </>
        );
      case "image":
        return (
          <>
            {data?.url?.value && (
              <img
                loading="lazy"
                className="tile-modal-type__image lazyload"
                src={data?.url?.value}
              />
            )}
          </>
        );
      case "music":
        return (
          <>
            <div className="tile-modal__field tile-modal__field--size">
              {data?.audio?.value && (
                <div className="tile-modal__upload-audio">
                  <AudioPlayer
                    autoPlay={data?.autoplay?.value}
                    src={data?.audio?.value}
                    onPlay={(e) => console.log("onPlay")}
                    onPlayError={(err) => {
                      debugger;
                    }}
                    // other props here
                    header={`Now playing:`}
                    showSkipControls={false}
                    showJumpControls={false}
                    className="tile-modal__audio"
                    customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
                  />
                </div>
              )}
            </div>
          </>
        );
      case "mpFlyover":
        return (
          <>
            <div className="tile-modal__field">
              <div className="tile-modal--subtitle">Text</div>
              <CustomInput
                name="text"
                formData={data?.text}
                onChange={(e) => {}}
                maxLength={500}
                variantError="topright"
              />
            </div>
            <div className="tile-modal__field">
              <div className="tile-modal--subtitle">URL</div>
              <CustomInput
                name="url"
                formData={data?.url}
                onChange={(e) => {}}
                variantError="topright"
              />
            </div>
          </>
        );
      default:
        return <div></div>;
    }
  };

  useEffect(() => {
    if (typeLink?.value && typeTile) {
      switch (typeLink?.value) {
        case "text":
          setData({
            descriptions: {
              value: current?.text || "",
              touched: false,
              hasError: true,
              error: "",
            },
          });
          break;
        case "video":
          setData({
            video: {
              value: urlMedia || "",
              touched: false,
              hasError: true,
              error: "",
            },
          });
          break;
        case "iframe":
          setData({
            url: {
              value: current?.url,
              touched: false,
              hasError: true,
              error: "",
            },
            text: {
              value: current?.title,
              touched: false,
              hasError: true,
              error: "",
            },
          });
          break;
        case "image":
          setData({
            url: {
              value: urlMedia || "",
              touched: false,
              hasError: true,
              error: "",
            },
          });
          break;
        case "newTab":
          setData({
            url: {
              value: current?.url,
              touched: false,
              hasError: true,
              error: "",
            },
            text: {
              value: current?.text,
              touched: false,
              hasError: true,
              error: "",
            },
          });
          break;
        case "mpFlyover":
          setData({
            url: {
              value: current?.url,
              touched: false,
              hasError: true,
              error: "",
            },
            text: {
              value: current?.text,
              touched: false,
              hasError: true,
              error: "",
            },
          });
          break;
        case "mpDeeplink":
          setData({
            url: {
              value: current?.url,
              touched: false,
              hasError: true,
              error: "",
            },
            text: {
              value: current?.text,
              touched: false,
              hasError: true,
              error: "",
            },
          });
          break;
        default:
          return <div></div>;
      }
    } else {
    }
  }, [current, typeLink, typeTile]);
  return (
    <>
      <div className="tile-modal-type">
        <CustomModal
          title={`${current?.title || ""}`}
          close={close}
          closeWithoutHide={true}
          cancelBtn="Cancel"
          isSubmitBtn={false}
          isCancelBtn={false}
        >
          <div className="type-modal-type--content">{renderType()}</div>
        </CustomModal>
      </div>
    </>
  );
};

export default ModalTileType;
